import React, { memo, ReactNode, useMemo, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { uris } from "@/config/nav";
import { useT } from "@/hooks/useT";
import { clientUrl } from "@/util/routing";
import { useQ } from "@/hooks/useQ";
import { portfolio, project } from "@/queries";
import { useFlag } from "@/hooks/useFlag";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import moment from "moment";
import { isEmpty, isNil } from "ramda";

type LinkItem = {
  id: string;
  labelKey?: string;
  label?: string;
  minLevel?: number;
  to?: string;
  params?: any;
  children?: LinkItem[];
  end?: boolean;
  onClick?: () => void;
};

const MenuLink = memo(
  ({
    item: { labelKey, label, to, params, children, end, onClick },
    level = 1,
  }: {
    item: LinkItem;
    level?: number;
  }) => {
    const { t } = useT();
    const navigate = useNavigate();
    const location = useLocation();

    const active = to
      ? matchPath(
          {
            path: clientUrl(to, params) + (end ? "" : "/*"),
          },
          location.pathname,
        )
      : false;

    const text = labelKey ? t(labelKey) : label;
    return (
      <>
        <ListItem
          sx={{
            py: 0,
            borderLeft: `${
              active && level === 1 ? "3px solid #666" : "2px solid #ccc"
            }`,
            pl: active && level === 1 ? 0 : "1px",
          }}
        >
          <ListItemButton
            sx={{
              pl: `${8 + (level - 1) * 10}px`,
            }}
            onClick={() =>
              onClick ? onClick() : navigate(clientUrl(to, params))
            }
          >
            <ListItemText
              primary={text}
              sx={{
                "& .MuiTypography-root": {
                  fontWeight: active && level > 1 ? "bold" : "normal",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        {active &&
          children &&
          children.map((subItem) => (
            <MenuLink item={subItem} level={level + 1} />
          ))}
      </>
    );
  },
);

const ToggleMenu = memo(
  ({
    text,
    items,
    startsOpen = true,
  }: {
    text: string;
    items: LinkItem[];
    startsOpen?: boolean;
  }) => {
    const [open, setOpen] = useState(startsOpen);

    return (
      <>
        <ListItem sx={{ py: 0 }}>
          <ListItemButton
            sx={{
              pl: 0,
            }}
            onClick={() => setOpen((t) => !t)}
          >
            <ListItemIcon
              sx={{
                minWidth: "auto",
                mr: 1,
              }}
            >
              {open ? <FolderOpenIcon /> : <FolderIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
            <ListItemIcon
              sx={{
                minWidth: "auto",
              }}
            >
              {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {open && (
          <List
            sx={{
              ml: 3,
              p: 0,
            }}
          >
            {items.map((item) => (
              <MenuLink key={item.id} item={item} />
            ))}
          </List>
        )}
      </>
    );
  },
);

const useProjectReports = (id: string, reportId: string, type: string) => {
  const { t } = useT();
  const useNewCheckpoint = useFlag("checkpoints.useRestApiV1");
  const useOptimizedReports = useFlag("project.optimize.reports");
  const { data: checkpoints = [] } = useQ(
    `project-${id}-report-checkpoints`,
    () => project.report.checkpoints({ id }),
  );
  const { data: highlights = [], isFetched } = useQ(
    `project-${id}-report-highlights`,
    () =>
      project.report.highlights({
        id,
      }),
    { enabled: !useOptimizedReports },
  );
  const {
    data: reportList = [],
    isFetched: fetchedReports,
    isLoading,
  } = useQ(
    `project-${id}-report-list`,
    () =>
      project.report.list({
        id,
        type,
      }),
    { enabled: !!useOptimizedReports },
  );

  const reports = useMemo(() => {
    const filterType = !type ? "highlight" : type;
    const filteredReports = useOptimizedReports
      ? reportList.filter((report) => report.type === filterType)
      : getReportsByType(filterType);

    return filteredReports
      .slice()
      .sort(compareReportsByDateAndDraftStatus)
      .map(enrichReportWithVersion)
      .reverse()
      .filter(filterUniqueOrRelevantReports)
      .map(formatReportForDisplay);
  }, [
    useNewCheckpoint,
    useOptimizedReports,
    isLoading,
    highlights,
    checkpoints,
    type,
    reportId,
  ]);

  function getReportsByType(type) {
    switch (type) {
      case "checkpoint":
        return useNewCheckpoint
          ? highlights.filter((report) => report.type === "checkpoint")
          : checkpoints;
      case "highlight":
      case !type:
        return highlights.filter(
          (report) =>
            !!report.stage &&
            report.endProject !== true &&
            report.type !== "checkpoint",
        );
      case "endStage":
        return highlights.filter(
          (report) => !!report.stage && report.endProject !== true,
        );
      case "endProject":
        return highlights.filter((report) => report.endProject === true);
      default:
        return [];
    }
  }

  function compareReportsByDateAndDraftStatus(a, b) {
    if (a.isDraft) return 1;
    if (b.isDraft) return -1;
    return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
  }

  function isSameDate(previous, current) {
    return moment(previous.to || previous.date).isSame(
      moment(current.to || current.date),
      "date",
    );
  }

  function enrichReportWithVersion(report, index, allReports) {
    const isSameDateAsPrevious =
      index > 0 && isSameDate(allReports[index - 1], report);
    const version = isSameDateAsPrevious
      ? allReports
          .slice(0, index)
          .filter((prevReport) => isSameDate(prevReport, report)).length + 1
      : undefined;

    return {
      ...report,
      to: report.to || report.date,
      date: formatDate(report.to || report.date),
      version,
    };
  }

  function filterUniqueOrRelevantReports(report, index, allReports) {
    return (
      index === 0 ||
      !isSameDate(allReports[index - 1], report) ||
      report.id === reportId ||
      (isSameDate(allReports[index - 1], report) &&
        allReports[index - 1].id === reportId)
    );
  }

  function formatReportForDisplay(report) {
    const versionLabel = report.version ? ` (v${report.version})` : "";
    const draftLabel = report.isDraft
      ? ` (${t("project.navigation.reports.draft")})`
      : "";

    return {
      name: `${report.date}${versionLabel}${draftLabel}`,
      id: report.id,
    };
  }

  function formatDate(date) {
    return new Date(date).toISOString().slice(0, 10);
  }

  function lastReportId(reports) {
    const lastReport = reports
      .slice()
      .sort((a, b) =>
        a.isDraft
          ? 1
          : b.isDraft
            ? -1
            : moment(a.date).isBefore(moment(b.date)) || a.to < b.to
              ? -1
              : moment(b.date).isBefore(moment(a.date)) || b.to < a.to
                ? 1
                : a.timestamp < b.timestamp
                  ? -1
                  : a.timestamp > b.timestamp
                    ? 1
                    : 0,
      )
      .slice(-1);
    return lastReport.length > 0 ? lastReport[0].id : undefined;
  }

  const lastCheckpointReportId = useNewCheckpoint
    ? lastReportId(
        reportList.filter(
          (report) => isNil(report.stage) && report.type === "checkpoint",
        ),
      ) !== undefined
      ? lastReportId(
          reportList.filter(
            (report) => isNil(report.stage) && report.type === "checkpoint",
          ),
        )
      : "noReport"
    : (isFetched || fetchedReports) && lastReportId(checkpoints) !== undefined
      ? lastReportId(checkpoints)
      : "noReport";

  const lastHighlightReportId =
    (isFetched || fetchedReports) &&
    lastReportId(
      reportList.filter(
        (report) =>
          isNil(report.stage) &&
          report.endProject !== true &&
          report.type !== "checkpoint",
      ),
    ) !== undefined
      ? lastReportId(
          reportList.filter(
            (report) =>
              isNil(report.stage) &&
              report.endProject !== true &&
              report.type !== "checkpoint",
          ),
        )
      : "noReport";

  const lastEndStageReportId =
    (isFetched || fetchedReports) &&
    lastReportId(
      reportList.filter((report) =>
        useOptimizedReports
          ? report.type === "endStage"
          : !isNil(report.stage) && report.endProject !== true,
      ),
    ) !== undefined
      ? lastReportId(
          reportList.filter((report) =>
            useOptimizedReports
              ? report.type === "endStage"
              : !isNil(report.stage) && report.endProject !== true,
          ),
        )
      : "noReport";

  const lastEndProjectReportId =
    (isFetched || fetchedReports) &&
    lastReportId(
      reportList.filter((report) =>
        useOptimizedReports
          ? report.type === "endProject"
          : report.endProject === true,
      ),
    ) !== undefined
      ? lastReportId(
          reportList.filter((report) =>
            useOptimizedReports
              ? report.type === "endProject"
              : report.endProject === true,
          ),
        )
      : "noReport";

  return {
    reports,
    lastCheckpointReportId,
    lastHighlightReportId,
    lastEndStageReportId,
    lastEndProjectReportId,
  };
};

const LinksNavigation = memo(() => {
  const { t } = useT();
  const { id = "null" } = useParams();
  const { data: links = [] } = useQ(`project-${id}-links`, () =>
    project.links({ id }),
  );

  return (
    <ToggleMenu
      text={t("project.navigation.links")}
      startsOpen={false}
      items={(links ?? []).map((item) => ({
        id: item.label,
        label: item.label,
        to: item.href,
      }))}
    />
  );
});

const ReportsNavigation = memo(() => {
  const { t } = useT();
  const { id = "null" } = useParams();

  return (
    <ToggleMenu
      text={t("project.navigation.reports.title")}
      items={[
        {
          id: "brief",
          label: t("project.navigation.reports.brief"),
          to: clientUrl(uris.project.brief, { id }),
        },
        {
          id: "pid",
          label: t("project.navigation.reports.pid"),
          to: clientUrl(uris.project.pid, { id }),
          minLevel: 3,
        },
      ]}
    />
  );
});

const ProjectActionsNavigation = memo(() => {
  const { t } = useT();
  const { id = "null" } = useParams();
  const useLog = useFlag("project.log", id);

  return (
    <ToggleMenu
      text={t("project.navigation.actions.title")}
      startsOpen={true}
      items={[
        {
          id: "editProperties",
          label: t("project.navigation.actions.editProperties"),
          to: clientUrl(uris.project.editProperties, { id }),
        },
        {
          id: "openLog",
          label: t("project.navigation.actions.openLog"),
          to: useLog
            ? clientUrl(uris.project.log, { id })
            : `/external/projects/${id}/log`,
        },
        {
          id: "editLinks",
          label: t("project.navigation.actions.editLinks"),
          to: `/projects/${id}/editLinks`,
        },
        {
          id: "settings",
          label: t("project.navigation.actions.settings"),
          to: clientUrl(uris.project.settings, { id }),
        },
      ]}
    />
  );
});

const ProjectNavigation = memo(
  ({ progressMenuOpen }: { progressMenuOpen: boolean }) => {
    const { id = "null", reportId, type = "highlight", version } = useParams();
    const { data: projectData = {} } = useQ(`project-${id}`, () =>
      project.single({ id }),
    );
    const level = projectData?.level ?? 3;
    const {
      reports,
      lastCheckpointReportId,
      lastHighlightReportId,
      lastEndStageReportId,
      lastEndProjectReportId,
    } = useProjectReports(id, reportId, type);

    const items: LinkItem[] = [
      {
        id: "home",
        labelKey: "project.navigation.home",
        to: uris.project.single,
        params: { id },
        end: true,
      },
      {
        id: "plans",
        labelKey: "project.navigation.plans.title",
        to: uris.project.plans,
        params: { id },
        minLevel: 3,
        children: [
          {
            id: "plan.schedule",
            labelKey: "project.navigation.plans.schedule",
            to: uris.project.plans,
            params: { id },
            end: true,
          },
          {
            id: "plan.approach",
            labelKey: "project.navigation.plans.approach",
            to: uris.project.plansApproach,
            params: { id },
            end: true,
          },
          {
            id: "plan.lessons",
            labelKey: "project.navigation.plans.lessons",
            to: uris.project.plansLessons,
            params: { id },
            end: true,
          },
          {
            id: "plan.controls",
            labelKey: "project.navigation.plans.controls",
            to: uris.project.plansControls,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "businessCase",
        labelKey: "project.navigation.businessCase",
        to: uris.project.businessCase,
        params: { id },
        minLevel: 3,
      },
      {
        id: "risk", // Provide a unique ID for this section
        labelKey: "project.navigation.risk.title",
        to: uris.project.risk,
        params: { id },
        minLevel: 2, // Add this if needed
        children: [
          {
            id: "risk.register",
            labelKey: "project.navigation.risk.register",
            to: uris.project.risk,
            params: { id },
            end: true,
          },
          {
            id: "risk.approach",
            labelKey: "project.navigation.risk.approach",
            to: uris.project.riskApproach,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "organization",
        labelKey: "project.navigation.organization.title",
        to: uris.project.organization,
        params: { id },
        children: [
          {
            id: "organization.team",
            labelKey: "project.navigation.organization.team",
            to: uris.project.organization,
            params: { id },
            end: true,
          },
          {
            id: "organization.communication",
            labelKey: "project.navigation.organization.communication",
            to: uris.project.organizationApproach,
            params: { id },
            end: true,
          },
          {
            id: "organization.stakeholder",
            labelKey: "project.navigation.organization.stakeholder",
            to: uris.project.organizationStakeholderAnalysis,
            params: { id },
          },
        ],
      },
      {
        id: "change",
        labelKey: "project.navigation.change.title",
        to: uris.project.change,
        params: { id },
        minLevel: 2,
        children: [
          {
            id: "change.issueRegister",
            labelKey: "project.navigation.change.issueRegister",
            to: uris.project.change,
            params: { id },
            end: true,
          },
          {
            id: "change.approach",
            labelKey: "project.navigation.change.approach",
            to: uris.project.changeApproach,
            params: { id },
            end: true,
          },
        ],
      },
      {
        id: "quality",
        labelKey: "project.navigation.quality",
        to: uris.project.quality,
        params: { id },
        minLevel: 3,
        children: [
          {
            id: "quality.register",
            labelKey: "project.quality.register.title",
            to: uris.project.quality,
            params: { id },
            end: true,
          },
          {
            id: "quality.ppd",
            labelKey: "project.quality.ppd.title",
            to: uris.project.quality + "/productDescription",
            params: { id },
          },
          {
            id: "quality.approach",
            labelKey: "project.quality.approach.title",
            to: uris.project.quality + "/approach",
            params: { id },
          },
        ],
      },
      {
        id: "progress",
        labelKey: "project.navigation.progress",
        to: uris.project.progressFull,
        params: {
          id,
          reportId: reportId ?? reports?.[0]?.id ?? "noReport",
          type,
        },
        minLevel: 3,
        children: [
          {
            id: "progress.checkpoint",
            labelKey: "project.progress.checkpointReport.title",
            to: uris.project.progressFull,
            params: {
              id,
              reportId: lastCheckpointReportId ?? "noReport",
              type: "checkpoint",
            },
            end: true,
          },
          {
            id: "progress.highlight",
            labelKey: "project.progress.highlightReport.title",
            to: uris.project.progressFull,
            params: {
              id,
              reportId: lastHighlightReportId,
              type: "highlight",
            },
            end: true,
          },
          {
            id: "progress.endStage",
            labelKey: "project.progress.endStage.title",
            to: uris.project.progressFull,
            params: {
              id,
              reportId: lastEndStageReportId,
              type: "endStage",
            },
            end: true,
          },
          {
            id: "progress.endProject",
            labelKey: "project.progress.endProject.title",
            to: uris.project.progressFull,
            params: {
              id,
              reportId: lastEndProjectReportId,
              type: "endProject",
            },
            end: true,
          },
        ],
      },
    ];

    return (
      <>
        <ToggleMenu
          text="Project"
          items={items.filter((item) =>
            typeof item.minLevel !== "undefined"
              ? level >= item.minLevel
              : true,
          )}
        />
        <LinksNavigation />
        {progressMenuOpen ? (
          <ProgressNavigation />
        ) : (
          <>
            <ReportsNavigation />
            <ProjectActionsNavigation />
            <ChangeStatusNavigation />
          </>
        )}
      </>
    );
  },
);

function lessonCount(lessons: { lessons: { type: string }[] }, type: string) {
  const count = lessons?.lessons?.filter((lesson) => lesson.type === type)
    .length;
  return count || 0;
}

const LessonsNavigation = memo(() => {
  const { t } = useT();
  const { data: lessons = [] } = useQ(`project-lessons`, () =>
    project.lessons(),
  );

  const items: LinkItem[] = [
    {
      id: "projects",
      label:
        t("project.plans.lessons.filter.project") +
        ` (${lessonCount(lessons, "project")})`,
      to: clientUrl(uris.lessons, { scope: "project" }),
    },
    {
      id: "portfolios",
      label:
        t("project.plans.lessons.filter.portfolio") +
        ` (${lessonCount(lessons, "portfolio")})`,
      to: clientUrl(uris.lessons, { scope: "portfolio" }),
    },
    {
      id: "programmes",
      label:
        t("project.plans.lessons.filter.program") +
        ` (${lessonCount(lessons, "programme")})`,
      to: clientUrl(uris.lessons, { scope: "program" }),
    },
    {
      id: "releases",
      label:
        t("project.plans.lessons.filter.corporate") +
        ` (${lessonCount(lessons, "corporate")})`,
      to: clientUrl(uris.lessons, { scope: "corporate" }),
    },
  ];

  return (
    <ToggleMenu text={t("project.navigation.plans.lessons")} items={items} />
  );
});

const PortfolioNavigation = memo(() => {
  const { t } = useT();
  const { isLoading, data = [] } = useQ(`portfolios`, () => portfolio.list());

  const items: LinkItem[] = data.map(({ name, id }) => ({
    id: `portfolio-${id}`,
    label: name,
    to: uris.portfolio.single,
    params: { id },
    end: true,
  }));

  return <ToggleMenu text={t("navigation.portfolios.title")} items={items} />;
});

const ChangeStatusNavigation = memo(() => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight", version } = useParams();
  const { data: projectData = {}, refetch } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const toast = useToast();
  const pauseProject = useMutation(project.pause, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been paused");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error pausing project");
    },
  });
  const startProject = useMutation(project.start, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been started");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error starting project");
    },
  });
  const stopProject = useMutation(project.stop, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project has been stoped");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error stoping projec");
    },
  });
  const prepareProjectClosure = useMutation(project.prepareClosure, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Closure has been prepared");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error preparing project closure");
    },
  });
  const abortProjectClosure = useMutation(project.abortClosure, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Closure has been aborted");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error aborting project closure");
    },
  });
  const approveClosure = useMutation(project.approveClosure, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project closed approved");
      refetch();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error approving project closure");
    },
  });

  const items: LinkItem[] = [];
  if (!projectData.isStarted) {
    items.push({
      id: "start",
      label: t("project.navigation.changeStatus.start"),
      onClick: () =>
        startProject.mutate({
          id,
          data: {},
        }),
    });
  }

  if (!(projectData.isPaused || projectData.isStopped)) {
    items.push({
      id: "pause",
      label: t("project.navigation.changeStatus.pause"),
      onClick: () =>
        pauseProject.mutate({
          id,
          data: {},
        }),
    });
  }

  if (!(projectData.isPaused || projectData.isStopped)) {
    items.push({
      id: "stop",
      label: t("project.navigation.changeStatus.stop"),
      onClick: () =>
        stopProject.mutate({
          id,
          data: {},
        }),
    });
  }

  if (
    !projectData.isClosing &&
    !projectData.isCompleted &&
    !!projectData.isStarted
  ) {
    items.push({
      id: "prepare",
      label: t("project.navigation.changeStatus.prepareClosure"),
      onClick: () =>
        prepareProjectClosure.mutate({
          id,
          data: {},
        }),
    });
  }

  if (!!projectData.recommendedProjectClosure) {
    items.push({
      id: "abortProjectClosure",
      label: t(
        "project.overview.activities.closeProject.actions.abortClosure.title",
      ),
      onClick: () =>
        abortProjectClosure.mutate({
          id,
          data: {},
        }),
    });
  }

  if (!!projectData.isClosing && projectData.level < 3) {
    items.push({
      id: "approveClosure",
      label: t(
        "project.overview.activities.closeProject.actions.approveClosure.title",
      ),
      onClick: () =>
        approveClosure.mutate({
          id,
        }),
    });
  }

  return <ToggleMenu text="Change Status" items={items} />;
});

const ProgressNavigation = memo(() => {
  const { t } = useT();
  const { id = "null", reportId, type = "highlight" } = useParams();

  return (
    <ToggleMenu
      text={t("navigation.projects.progress.view.title")}
      items={[
        {
          id: "summary",
          label: t("navigation.projects.progress.view.summary"),
          to: clientUrl(uris.project.progressFull, {
            id,
            type: "highlight",
            reportId,
          }),
        },
        {
          id: "fullscreen",
          label: t("navigation.projects.progress.view.fullscreen"),
          onClick: () => {
            window
              .open(
                clientUrl(uris.project.progressFullscreen, {
                  id,
                  type: "highlight",
                  reportId,
                }),
                "_blank",
              )
              .focus();
          },
        },
        {
          id: "pdf",
          label: t("navigation.projects.progress.view.pdf"),
          to: clientUrl(uris.project.progressReportIdPdf, {
            id,
            reportId,
          }),
        },
      ]}
    />
  );
});

export const DrawerNavigation = memo(() => {
  const location = useLocation();

  const match = (path: string) =>
    matchPath(
      {
        path,
      },
      location.pathname,
    );

  const isProject = match(uris.project.single + "/*");
  const isProjectProgress = match(uris.project.progressType + "/*");

  return (
    <List>
      {!isProject && (
        <>
          <PortfolioNavigation />
          <LessonsNavigation />
        </>
      )}
      {isProject && (
        <ProjectNavigation progressMenuOpen={!!isProjectProgress} />
      )}
    </List>
  );
});
